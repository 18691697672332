import React from 'react'
import { object } from '@style-space/components/src/Form/schema'
import styled, { css } from 'styled-components'
import { FormikInput } from '@style-space/components/src/Form/Input/FormikInput'
import { ConfirmationModalInner } from '@style-space/components/src/Modal/ConfirmationModal/ConfirmationModalInner'
import { Form } from '@style-space/components/src/Form'

import { pxToRem } from '../../../utils/utils'
import { Title } from '../../common/Title'
import { EMAIL_SCHEMA } from '../../../utils/schemas'

const Wrap = styled.div`
  width: 100%;
  margin: 0 auto;
`

const SForm: typeof Form = styled(Form)`
  width: 100%;

  .form-submit {
    width: 100%;
    margin-top: ${pxToRem(16)};
    white-space: nowrap;
  }
` as any

const STitle = styled(Title)(
  ({ theme }) => css`
    width: 100%;
    text-align: left;
    font-size: ${pxToRem(32)};
    font-weight: ${theme.font.weight.regular};
    line-height: 1.125;
    color: ${theme.color.grey['900']};
    margin: ${pxToRem(0, 0, 40)};
  `,
)

const SFormikInput = styled(FormikInput)`
  margin-bottom: ${pxToRem(40)};
`

const Text = styled.p(
  ({ theme }) => css`
    width: 100%;
    color: ${theme.color.grey['900']};
    margin: ${pxToRem(0, 0, 40)};
    text-align: left;
    line-height: 1.5;
    font-size: ${pxToRem(16)};
  `,
)

const SCHEMA = object().shape({
  email: EMAIL_SCHEMA,
})

const INIT_VALUES = {
  email: '',
}

export type ForgotValues = typeof INIT_VALUES

type Props = {
  className?: string
  values?: ForgotValues
  onSubmit(values: ForgotValues): void
}

export const ForgotForm: React.FC<Props> = ({
  className,
  values = {},
  onSubmit,
}) => {
  const [sent, setSent] = React.useState(false)

  const onFormSubmit = React.useCallback(
    (values: ForgotValues) => {
      setSent(true)
      onSubmit(values)
    },
    [setSent, onSubmit],
  )

  if (sent) {
    return (
      <ConfirmationModalInner
        title="E-mail sent"
        text="Please check your email. If the e-mail is in our database we have sent the reset link."
        icon="check"
      />
    )
  }

  return (
    <Wrap data-testid="forgotForm" className={className}>
      <SForm
        hideOnNonDirty={false}
        initialValues={{ ...INIT_VALUES, ...values }}
        validationSchema={SCHEMA}
        validateOnBlur
        submitText="SEND RESET E-MAIL"
        onSubmit={onFormSubmit}
      >
        <STitle type="h1">Reset password</STitle>
        <Text>
          If the e-mail is in our database you will receive a reset link.
        </Text>
        <SFormikInput
          popError
          label="E-mail"
          data-testid="forgotEmail"
          name="email"
          placeholder="Enter your e-mail"
        />
      </SForm>
    </Wrap>
  )
}
ForgotForm.displayName = 'ForgotForm'
