import React, { InputHTMLAttributes, useContext } from 'react'
import { ThemeContext } from 'styled-components'

import {
  Wrap,
  InnerWrap,
  SInput,
  Label,
  Description,
  Info,
} from './Input.style'
import { Error } from '../Error'
import { DefaultTheme } from '../../themes'

export type Props = InputHTMLAttributes<HTMLInputElement> & {
  label: string
  value: string
  description?: string
  setValue(value: string | number): void
  error?: string | null
  popError?: boolean
  className?: string
  hideLabel?: boolean
  info?: string
}

export const Input: React.FC<Props> = ({
  id = '',
  label,
  value = '',
  setValue,
  description,
  className = '',
  popError,
  type,
  children,
  error,
  info,
  hideLabel = false,
  ...rest
}) => {
  const theme = useContext(ThemeContext) || DefaultTheme
  const errorClass = Boolean(error) ? 'error ' : ''

  const onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    ev.preventDefault()
    const { value, valueAsNumber } = ev.target
    if (type === 'number' && valueAsNumber) {
      setValue(valueAsNumber)
      return
    }
    setValue(value)
  }

  return (
    <Wrap
      theme={theme}
      className={`${errorClass} ${className}`}
      data-testid="input"
    >
      <Label
        className={`input-label ${hideLabel ? 'hideLabel' : ''}`}
        theme={theme}
        htmlFor={id}
        data-testid="input-label"
      >
        {label}
      </Label>
      {description && (
        <Description theme={theme} className="input-description">
          {description}
        </Description>
      )}
      <InnerWrap theme={theme}>
        <SInput
          theme={theme}
          id={id}
          name={id}
          type={type}
          className={`${errorClass} 'input-field'`}
          data-testid="input-field"
          {...rest}
          value={value}
          onChange={onChange}
        />
        {children}
      </InnerWrap>
      <Error error={error} popError={popError} />
      {info && <Info>{info}</Info>}
    </Wrap>
  )
}
Input.displayName = 'Input'
