import React from 'react'
import styled, { css } from 'styled-components'

import { Modal } from '../../modals/Modal'
import { pxToRem } from '../../../utils/utils'
import { ForgotForm, ForgotValues } from './ForgotForm'
import { useForgot } from '../../../data/hooks/User/useForgot'

const SModal = styled(Modal)(
  ({ theme }) => css`
    .headless-modal-inner-wrap {
      padding: ${pxToRem(64)};
      max-width: ${pxToRem(600)};

      .headless-modal-x {
        top: ${pxToRem(30)};
        right: ${pxToRem(30)};
        padding: ${pxToRem(8)};
        svg {
          width: ${pxToRem(14)};
          height: ${pxToRem(14)};
        }
      }
    }

    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      .headless-modal-inner-wrap {
        padding: ${pxToRem(64, 45)};
      }
    }
  `,
)

type Props = {
  isOpen: boolean
  onClose(): void
  className?: string
}

export const ForgotModal: React.FC<Props> = ({
  isOpen,
  onClose,
  className,
}) => {
  const { forgot } = useForgot()

  const onSubmit = React.useCallback(
    async ({ email }: ForgotValues) => {
      await forgot(email)
    },
    [forgot],
  )

  return (
    <SModal
      modalName="Reset password"
      isOpen={isOpen}
      onClose={onClose}
      className={className}
    >
      <ForgotForm onSubmit={onSubmit} />
    </SModal>
  )
}
ForgotModal.displayName = 'ForgotModal'
