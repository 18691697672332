import styled, { css } from 'styled-components'

import { Anchor } from '../../../Anchor/Anchor'
import { pxToRem } from '../../../lib/utils'
import { Button } from '../../../Button'
import { Heading } from '../../../Heading'
import { XIcon } from '../../../icons/XIcon'
import { Check } from '../../../icons/Check'

export const SHeading = styled(Heading)`
  margin-bottom: ${pxToRem(40)};
  line-height: 1.125;
  text-align: center;
`

export const IconWrap = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${pxToRem(96)};
    height: ${pxToRem(96)};
    border-radius: 50%;
    background: ${theme.color.grey['100']};
    margin: auto;
  `,
)

export const Text = styled.div(
  ({ theme }) => css`
    margin: ${pxToRem(32, 0, 40)};
    color: ${theme.color.grey['900']};
    font-size: ${pxToRem(16)};
    line-height: 1.5;
    text-align: center;
  `,
)

export const SXIcon = styled(XIcon)(
  ({ theme }) => css`
    path {
      fill: ${theme.color.primary['500']};
    }
    width: ${pxToRem(24)};
    height: ${pxToRem(24)};
  `,
)

export const SCheck = styled(Check)(
  ({ theme }) => css`
    path {
      fill: ${theme.color.primary['500']};
      stroke: ${theme.color.primary['500']};
    }
    width: ${pxToRem(40)};
    height: ${pxToRem(40)};
  `,
)

export const Line = styled.div(
  ({ theme }) => css`
    background-color: ${theme.color.grey['200']};
    height: ${pxToRem(1)};
    width: 100%;
  `,
)

export const LineText = styled.div(
  ({ theme }) => css`
    font-size: ${pxToRem(16)};
    margin: ${pxToRem(0, 16)};
    line-height: 1.5;
    color: ${theme.color.grey['500']};
  `,
)

export const LineWrap = styled.div`
  display: flex;
  margin: ${pxToRem(16, 0)};
  width: 100%;
  align-items: center;
`

export const SButton = styled(Button)`
  width: 100%;
`

export const SAnchor = styled(Anchor)`
  && {
    max-width: 100%;
  }
`
