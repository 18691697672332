import React from 'react'
import styled, { css } from 'styled-components'

const Svg = styled.svg(
  ({ theme }) => css`
    path {
      fill: ${theme.color.primary['500']};
    }
  `,
)

type Props = {
  className?: string
}

export const ShieldCheck: React.FC<Props> = ({ className }) => {
  return (
    <Svg
      className={className}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.888 3.6653C19.5208 3.09894 20.4783 3.09895 21.1111 3.66532C24.3557 6.56939 28.6364 8.33326 33.333 8.33326C33.6502 8.33326 33.9654 8.32522 34.2784 8.30935C35.0686 8.26927 35.7781 8.79028 35.9763 9.55625C36.427 11.2975 36.6663 13.122 36.6663 15C36.6663 25.0981 29.7597 33.5788 20.4151 35.984C20.1426 36.0541 19.8568 36.0541 19.5842 35.984C10.2396 33.5788 3.33301 25.0981 3.33301 15C3.33301 13.1219 3.57235 11.2973 4.02307 9.55603C4.22133 8.79007 4.93079 8.26907 5.72098 8.30914C6.03395 8.32502 6.34913 8.33306 6.66634 8.33306C11.3629 8.33306 15.6434 6.56926 18.888 3.6653ZM6.96877 11.6643C6.77024 12.745 6.66634 13.8597 6.66634 15C6.66634 23.39 12.3037 30.4675 19.9997 32.6437C27.6956 30.4675 33.333 23.39 33.333 15C33.333 13.8598 33.2291 12.7451 33.0306 11.6645C28.1202 11.5973 23.6018 9.89495 19.9995 7.07918C16.3974 9.89482 11.8791 11.5971 6.96877 11.6643ZM26.1782 15.4882C26.8291 16.139 26.8291 17.1943 26.1782 17.8452L19.5115 24.5118C18.8606 25.1627 17.8054 25.1627 17.1545 24.5118L13.8212 21.1785C13.1703 20.5276 13.1703 19.4724 13.8212 18.8215C14.472 18.1706 15.5273 18.1706 16.1782 18.8215L18.333 20.9763L23.8212 15.4882C24.472 14.8373 25.5273 14.8373 26.1782 15.4882Z"
        fill="#286F8C"
      />
    </Svg>
  )
}
ShieldCheck.displayName = 'ShieldCheck'
