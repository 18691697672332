import React, { useContext } from 'react'
import styled, { css, ThemeContext } from 'styled-components'

import { DefaultTheme } from '../themes'

const Svg = styled.svg(
  ({ theme }) => css`
    path {
      fill: ${theme.color.primary['500']};
      stroke: ${theme.color.primary['500']};
    }
  `,
)

type Props = {
  className?: string
}

export const Check: React.FC<Props> = ({ className, ...rest }) => {
  const theme = useContext(ThemeContext) || DefaultTheme
  return (
    <Svg
      {...rest}
      theme={theme}
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1381 4.19526C13.3984 4.45561 13.3984 4.87772 13.1381 5.13807L6.47141 11.8047C6.21106 12.0651 5.78895 12.0651 5.5286 11.8047L2.86193 9.13807C2.60158 8.87772 2.60158 8.45561 2.86193 8.19526C3.12228 7.93491 3.54439 7.93491 3.80474 8.19526L6 10.3905L12.1953 4.19526C12.4556 3.93491 12.8777 3.93491 13.1381 4.19526Z"
      />
    </Svg>
  )
}
Check.displayName = 'Check'
