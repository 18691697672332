import React from 'react'
import { useMutation } from '@style-space/components/src/lib/graphql/useMutation'

export const MUTATION = `
  mutation forgot($email: String!) {
    forgotPassword(email: $email)
  }
`

export const useForgot = () => {
  const { mutate, loading, error } = useMutation<
    { forgotPassword: string },
    { email: string }
  >(MUTATION)

  const forgot = React.useCallback(async (email: string) => {
    await mutate({
      variables: { email },
    })
  }, [])

  return {
    forgot,
    loading,
    error,
  }
}
