import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'

import {
  SHeading,
  Text,
  SButton,
  IconWrap,
  SCheck,
  SXIcon,
  LineWrap,
  Line,
  LineText,
  SAnchor,
} from './ConfirmationModalInner.style'
import { DefaultTheme } from '../../../themes'
import { ShieldCheck } from '../../../icons/ShieldCheck'

export type Props = {
  title: string | JSX.Element
  icon: 'x' | 'shield' | 'check'
  text?: string
  buttonName?: string
  buttonFn?: () => void
  secondaryButtonName?: string
  secondaryButtonFn?: () => void
  linkHref?: string
  linkHrefBlank?: boolean
  secondaryLinkHref?: string
  children?: React.ReactNode
}

export const ConfirmationModalInner: React.FC<Props> = ({
  text,
  icon,
  buttonName,
  buttonFn,
  secondaryButtonFn,
  secondaryButtonName,
  title,
  linkHref,
  linkHrefBlank,
  secondaryLinkHref,
  children,
}) => {
  const theme = useContext(ThemeContext) || DefaultTheme
  return (
    <>
      <SHeading
        data-testid="confirmationModalTitle"
        type="h1"
        headingType="style3"
      >
        {title}
      </SHeading>
      <IconWrap theme={theme}>
        {icon === 'check' && <SCheck theme={theme} />}
        {icon === 'x' && <SXIcon theme={theme} />}
        {icon === 'shield' && <ShieldCheck />}
      </IconWrap>
      <Text data-testid="confirmationModalText" theme={theme}>
        {text}
        {children}
      </Text>
      {buttonName && linkHref && (
        <SAnchor
          uppercase
          target={linkHrefBlank ? '_blank' : undefined}
          data-testid="confirmationModalButton"
          anchorType="button primary regular"
          href={linkHref}
        >
          {buttonName}
        </SAnchor>
      )}
      {buttonName && buttonFn && (
        <SButton
          data-testid="confirmationModalButton"
          buttonType="primary"
          buttonSize="regular"
          onClick={buttonFn}
        >
          {buttonName}
        </SButton>
      )}
      {secondaryButtonName && secondaryLinkHref && (
        <>
          <LineWrap theme={theme}>
            <Line theme={theme} />
            <LineText theme={theme}>or</LineText>
            <Line theme={theme} />
          </LineWrap>
          <SAnchor
            uppercase
            data-testid="confirmationModalSecondaryButton"
            anchorType="button secondary regular"
            href={secondaryLinkHref}
          >
            {secondaryButtonName}
          </SAnchor>
        </>
      )}
      {secondaryButtonName && secondaryButtonFn && (
        <>
          <LineWrap theme={theme}>
            <Line theme={theme} />
            <LineText theme={theme}>or</LineText>
            <Line theme={theme} />
          </LineWrap>
          <SButton
            data-testid="confirmationModalSecondaryButton"
            buttonType="secondary"
            buttonSize="regular"
            onClick={secondaryButtonFn}
          >
            {secondaryButtonName}
          </SButton>
        </>
      )}
    </>
  )
}
ConfirmationModalInner.displayName = 'ConfirmationModalInner'
