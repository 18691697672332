import React, { HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'

import { HeaderStrength } from '../../../@types/types'
import { pxToRem } from '../../utils/utils'

const Header = styled.div(
  ({ theme }) => css`
    position: relative;
    top: 0;
    font-family: ${theme.font.poppins};
    color: ${theme.color.dark};
    margin: 0;
    font-weight: ${theme.font.weight.regular};
    line-height: 1.3;
    font-size: ${pxToRem(32)};

    &.line {
      top: 0.5rem;
    }
  `,
)

const Line = styled.div(
  ({ theme }) => css`
    width: 1.25rem;
    height: 0.125rem;
    position: absolute;
    top: -0.5rem;
    background-color: ${theme.color.blue};
  `,
)

type Props = HTMLAttributes<HTMLHeadingElement> & {
  className?: string
  type: HeaderStrength
  line?: boolean
}

export const Title: React.FC<Props> = ({
  className = '',
  type,
  line = false,
  children,
  ...rest
}) => {
  const lineClass = line ? 'line ' : ''
  return (
    <Header className={`${lineClass}${className}`} as={type} {...rest}>
      {line && <Line data-testid="titleLine" />}
      {children}
    </Header>
  )
}

export const WhiteTitle = styled(Title)(
  ({ theme }) => css`
    color: ${theme.color.white};

    > div {
      background-color: ${theme.color.orange};
    }
  `,
)

Title.displayName = 'Title'
