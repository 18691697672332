import styled, { css } from 'styled-components'

import { pxToRem } from '../../lib/utils'

export const SInput = styled.input(
  ({ theme }) => css`
    position: relative;
    border-radius: ${theme.radius.small};
    padding: ${pxToRem(11, 15)};
    width: 100%;
    border: ${pxToRem(1)} solid ${theme.color.grey['300']};
    font-size: ${pxToRem(16)};
    line-height: 1.5;
    color: ${theme.color.grey['900']};
    background: ${theme.color.white};
    ::placeholder {
      color: ${theme.color.grey['500']};
      font-weight: ${theme.font.weight.regular};
    }
    :hover {
      border-color: ${theme.color.grey['400']};
    }
    :focus {
      outline: none;
      border-color: ${theme.color.primary['500']};
      box-shadow: 0px 0px 0px 1px ${theme.color.primary['500']};
    }

    &.error {
      border-color: ${theme.color.red['500']};
      :hover {
        border-color: ${theme.color.red['700']};
      }
      :focus {
        border-color: ${theme.color.red['500']};
        box-shadow: ${pxToRem(0, 0, 0, 1)} ${theme.color.red['500']};
      }
    }

    :disabled {
      opacity: 1;
      -webkit-text-fill-color: ${theme.color.grey['500']};
      background: ${theme.color.grey['100']};
      border-color: ${theme.color.grey['300']};
      color: ${theme.color.grey['400']};
    }
  `,
)

export const Label = styled.label(
  ({ theme }) => css`
    color: ${theme.color.grey['900']};
    font-weight: ${theme.font.weight.regular};
    display: block;
    font-size: ${pxToRem(16)};
    line-height: 1.5;
    margin-bottom: ${pxToRem(4)};

    &.hideLabel {
      position: absolute;
      left: -9999px;
    }
  `,
)

export const Wrap = styled.div`
  position: relative;
  width: 100%;
  height: auto;
`

export const Description = styled.div(
  ({ theme }) => css`
    color: ${theme.color.grey['700']};
    font-size: ${pxToRem(16)};
    margin: 0.625rem 0;
    line-height: 1.5;

    .error & {
      color: ${theme.color.red['500']};
    }
  `,
)

export const InnerWrap = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  height: auto;
`

export const Info = styled.div(
  ({ theme }) => css`
    margin-top: ${pxToRem(8)};
    color: ${theme.color.grey['500']};
    font-size: ${pxToRem(16)};
    line-height: 1.5;
  `,
)
